@import "general/colors";
@import "general/buttons";
@import "general/typography";

$green-500: #22c55e;
$green-700: #15803d;
$lightBlue-500: #00c0eb;
$lightBlue-700: #0a7194;
$yellow-500: #ffc70e;
$yellow-700: #a37d00;
$blue-500: #3b82f6;
$blue-900: #1e3a8a;
$pink-500: #ec4899;
$pink-900: #831843;
$indigo-500: #6366f1;
$indigo-900: #312e81;
$teal-500: #14b8a6;
$teal-700: #0f766e;
$orange-500: #f97316;
$orange-700: #c2410c;

.main-menu {
  &-space {
    width: 100%;
    height: 92px;
    @media screen and (max-width: 1440px) {
      height: 72px;
    }
    @media screen and (max-width: 1205px) {
      height: 75px;
    }
  }
  .desktop-menu {
    z-index: 1000;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .responsive-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  a {
    color: $text;
    text-decoration: none;
  }
  #flush-headingTwo {
    & .accordion-button::after {
      display: none;
    }
  }
  #flush-headingFour {
    & .accordion-button::after {
      display: none;
    }
  }
  #flush-headingOne {
    & .accordion-button > a:hover {
      color: $text;
    }
  }
  #flush-headingThree {
    & .accordion-button > a:hover {
      color: $text;
    }
  }
  .bx {
    color: $text;
    font-size: 28px;
    cursor: pointer;
  }
  .nav {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 92px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: $white;
    @media screen and (max-width: 1440px) {
      height: 72px;
    }
  }
  .nav__logo {
    cursor: pointer;
    @media screen and (max-width: 1440px) {
      height: 50px;
    }
    @media screen and (max-width: 1205px) {
      height: 59px;
    }
  }
  .nav__item {
    z-index: 100;
    font-family: $typography;
    font-style: normal;
    font-weight: bold;
    @include h5--strong;
    color: $text;
    text-transform: uppercase;
    cursor: pointer;
    position: relative;
    padding: 30px;
    &:hover {
      color: $primary-two;
    }
  }
  .nav__submenu {
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: $white;
    gap: 20px;
    top: 110%;
    left: -100%;
    padding: 10px;
    display: none;
    &--item {
      color: $extra;
      @include h5;
      text-transform: none;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &-icon {
        width: 60px;
      }
    }
    li {
      &:nth-child(1) .nav__submenu--item:hover {
        color: $green-500;
      }
      &:nth-child(2) .nav__submenu--item:hover {
        color: $lightBlue-500;
      }
      &:nth-child(3) .nav__submenu--item:hover {
        color: $yellow-500;
      }
      &:nth-child(4) .nav__submenu--item:hover {
        color: $blue-500;
      }
      &:nth-child(5) .nav__submenu--item:hover {
        color: $pink-500;
      }
      &:nth-child(6) .nav__submenu--item:hover {
        color: $indigo-500;
      }
      &:nth-child(7) .nav__submenu--item:hover {
        color: $teal-500;
      }
      &:nth-child(8) .nav__submenu--item:hover {
        color: $orange-500;
      }
    }
  }
  .nav__item--success-stories:hover {
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-color: $white;
      position: absolute;
      bottom: -20%;
      left: 50%;
      transform: rotate(45deg);
    }
    &::before {
      content: "";
      width: 100%;
      height: 20px;
      background-color: transparent;
      position: absolute;
      bottom: -20%;
      left: 0;
    }
    & img {
      width: 60px;
    }
    & .nav__submenu--success-stories {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: baseline;
      left: -40%;
    }
  }
  .nav__item--what-we-do:hover {
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background-color: $white;
      position: absolute;
      bottom: -20%;
      left: 50%;
      transform: rotate(45deg);
    }
    &::before {
      content: "";
      width: 100%;
      height: 20px;
      background-color: transparent;
      position: absolute;
      bottom: -20%;
      left: 0;
    }
    & .nav__submenu--what-we-do {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
  }
  .nav__item--solutions:hover {
    & > .nav__item {
      text-transform: none;
    }
    & .nav__submenu--solutions {
      display: grid;
      grid-template-columns: repeat(3, 100px);
      left: -25%;
    }
  }
  .nav__item--blog {
    position: relative;

    &:hover {
      a {
        color: $primary-two;
      }
    }

    a::before {
      content: "New";
      color: #000;
      background: #ffc70e;
      border-radius: 5px;
      padding: 2px 5px;
      font-size: 10px;
      font-weight: bold;
      position: absolute;
      top: 10px;
      left: -16px;
      transform: rotate(-20deg);
      animation: bounce 1.5s infinite; // Añadimos la animación de bounce
      clip-path: polygon(0 0, 100% 0, 100% 70%, 50% 100%, 0 70%);
    }

    // Media query para pantallas de 1440px o más
    @media screen and (min-width: 1440px) {
      a::before {
        left: 0px;
      }
    }

    // Media query para pantallas de 1205px o más
    @media screen and (min-width: 1205px) {
      a::before {
        left: -5px;
      }
    }
  }

  // Definimos la animación de bounce (salto)
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0) rotate(-20deg);
    }
    50% {
      transform: translateY(-5px) rotate(-20deg);
    }
  }

  .nav__item--solutions {
    & > .nav__item {
      text-transform: none;
    }
  }
  .nav__button {
    background: $secondary;
    box-sizing: border-box;
    border-radius: 40px;
    text-transform: uppercase;
    font-family: $typography;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: $text;
    padding: 8px 15px;
    cursor: pointer;
    position: relative;
    isolation: auto;
    z-index: 10;
    overflow: hidden;
    display: block;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    gap: 0.5rem;
    border: 2px solid $secondary;
    &:hover {
      color: $secondary;
      &::before {
        right: 0;
        width: 100%;
        transform: scale(1.5);
        transition-duration: 700ms;
      }
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      transition: all 700ms;
      right: -100%;
      border-radius: 9999px;
      background-color: #ffffff;
      z-index: -10;
      aspect-ratio: 1 / 1;
    }
  }
  .nav__button--search {
    height: 100%;
    background-color: transparent;
    position: relative;
  }
  .nav__search-bar {
    position: absolute;
    top: 100%;
    background: rgba(255, 198, 14, 0.09);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 69px;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 20px;
    & > span {
      color: $white;
      cursor: pointer;
    }
    &--show {
      display: flex;
    }
  }
  .nav__textfield {
    background: $white;
    border-radius: 10px;
    width: 70%;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    & > input {
      flex-grow: 1;
    }
  }
  .nav__button--language {
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .dropdown--language {
    display: none;
    position: absolute;
    top: 100%;
    left: -20%;
    background-color: $white;
    padding: 10px;
  }
  .dropdown__item {
    @include h5--strong;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav__button--language:hover {
    & .dropdown--language {
      display: flex;
      z-index: 1;
      & > a {
        color: $text;
      }
    }
  }
  @media screen and (max-width: 1650px) {
    .nav__item {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      padding-left: 10px;
      padding-right: 10px;
      @include h5--strong;
    }
    .nav__button {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      display: block;
      @include h5--strong;
    }
    .nav__submenu {
      top: 120%;
      &--item {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.03em;
        &-icon {
          font-size: 40px;
        }
      }
    }
    @media screen and (max-width: 1440px) {
      .nav__item {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        padding-left: 5px;
        padding-right: 5px;
      }
      .nav__button {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        display: block;
      }
      .nav__submenu {
        top: 120%;
        &--item {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 17px;
          text-align: center;
          letter-spacing: 0.03em;
          &-icon {
            font-size: 40px;
          }
        }
      }
    }
    .nav__item--what-we-do:hover {
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $white;
        position: absolute;
        bottom: -30%;
        left: 50%;
        transform: rotate(45deg);
      }
      & .nav__submenu--what-we-do {
        grid-template-columns: repeat(4, 1fr);
        gap: 50px;
      }
      .nav__item--success-stories:hover {
        &::after {
          content: "";
          width: 20px;
          height: 20px;
          background-color: $white;
          position: absolute;
          bottom: -30%;
          left: 50%;
          transform: rotate(45deg);
        }
        & .nav__submenu--success-stories {
          gap: 50px;
        }
      }
    }
    .nav__item--success-stories:hover {
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        background-color: $white;
        position: absolute;
        bottom: -30%;
        left: 50%;
        transform: rotate(45deg);
      }
    }
  }
  @media screen and (max-width: 1205px) {
    .desktop-menu {
      display: none;
    }
    .nav--responsive {
      display: flex;
      background-color: $white;
    }
    .nav__submenu--responsive {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 40px;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
    .nav__submenu-success-stories--item {
      & > img {
        width: 60px;
      }
    }
    .nav__menu-rest--responsive {
      background-color: $white;
    }
    .nav__textfield-container--responsive {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      margin-bottom: 20px;
      & > input {
        display: none;
      }
      & > .bx-chevron-right {
        display: none;
      }
    }
    .nav__textfield--responsive {
      padding: 10px 20px;
      background: $white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 40px;
      width: 100%;
      height: 40px;
    }
    .nav__language--responsive {
      display: flex;
      gap: 20px;
      & > img {
        cursor: pointer;
      }
    }
    .nav__social-media--responsive {
      display: flex;
      gap: 20px;
      padding: 20px;
      justify-content: center;
      align-items: center;
      & .bx {
        font-size: 50px;
      }
    }
    .nav__search-bar--show {
      display: none;
    }
    .responsive-menu {
      display: block;
    }
  }
}

// .nav {
//     &>a:first-child {
//         order: 0;
//     }
//     &>.nav__container--items {
//         order: 1;
//     }
//     &>.nav__item--success-stories {
//         order: 2;
//     }
//     &>.nav__item--solutions {
//         order: 4;
//     }
//     &>.nav__item--what-we-do {
//         order: 3;
//     }
//     &>a:nth-child(6) {
//         order: 5;
//     }
//     &>a:nth-child(8) {
//         order: 7;
//     }
//     &>.nav__item--blog {
//         order: 6;
//     }
//     &>.nav__button--search {
//         order: 8;
//     }
//     &>.nav__button--language {
//         order: 9;
//     }
// }

//* ================== nav responsive ===============
button.accordion-button {
  @include h3;
  text-transform: uppercase;
  & > a {
    @include h3;
  }
  &:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
}

.accordion-item {
  border: none;
}

.accordion-button:not(.collapsed) {
  color: $white;
  background-color: $white;
  box-shadow: none;
  & > a {
    color: $primary-two;
  }
}

.nav__textfield-container--show {
  justify-content: center;
  position: relative;
  & > .bx-search {
    color: $primary-two;
  }
  & > input {
    display: block !important;
  }
  & > .bx-chevron-right {
    position: absolute;
    top: 3px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    display: block !important;
  }
}

.nav__language--show {
  gap: 90px !important;
  & > img {
    opacity: 0.5;
  }
  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    @include h3;
    &:hover {
      color: $text;
    }
  }
}

.nav--responsive {
  z-index: 1000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-toggler:focus {
  border: none;
  box-shadow: none;
}

//* ====================== responsive 320px ===========================
@media screen and (max-width: 573px) {
  //* ============ nav ====================
  .responsive-menu {
    max-height: 100vh;
    overflow-y: scroll;
  }
  .nav__submenu--responsive {
    flex-direction: column;
  }
  .nav__textfield--responsive {
    @include h5;
  }
  //* ======================= nav items ====================
  .accordion-button {
    padding: 0;
    & > a {
      @include h3;
    }
  }
  //* =============== social media ===================
  .main-menu .nav__social-media--responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    & .bx {
      font-size: 45px;
    }
  }
  //* ================== order nav items =================
  .accordion-header {
    padding: 20px 39px;
  }
  .nav__textfield-container--responsive {
    margin-bottom: 40px !important;
  }
  .nav__language--responsive {
    margin-bottom: 30px !important;
  }
}

//* ============ hide search button ====================
.nav__textfield-container--responsive {
  display: none !important;
}

#button-search {
  display: none;
}
